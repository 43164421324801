import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  const [salaryCLT, setSalaryCLT] = useState("");
  const [valeTransporte, setValeTransporte] = useState("");
  const [valeRefeicao, setValeRefeicao] = useState("");
  const [outrosBeneficios, setOutrosBeneficios] = useState("");
  const [aliquotaSimples, setAliquotaSimples] = useState("");
  const [result, setResult] = useState("");

  // const [resultMei, setResultMei] = useState("")

  const handleCalculate = () => {
    const salarioCLT = parseFloat(salaryCLT.replace(/[^\d.-]/g, "")); // Remover caracteres não numéricos
    const descontoCLT = salarioCLT * 0.23; // 23% de descontos CLT (INSS, IRRF)
    const beneficiosCLT = parseFloat(valeTransporte.replace(/[^\d.-]/g, "")) + parseFloat(valeRefeicao.replace(/[^\d.-]/g, "")) + parseFloat(outrosBeneficios.replace(/[^\d.-]/g, ""));
    const resultadoCLT = salarioCLT - descontoCLT + beneficiosCLT;

    // Cálculos para MEI
    const salarioMEI = salarioCLT;
    const descontoMEI = 75.60; // Desconto fixo de R$75.60 para MEI
    const resultadoMEI = salarioMEI - descontoMEI;


    // Cálculos para Simples Nacional
    const aliquotaSimplesValue = parseFloat(aliquotaSimples) / 100;
    const salarioSimples = salarioCLT;
    const descontoSimples = salarioSimples * aliquotaSimplesValue;
    const resultadoSimples = salarioSimples - descontoSimples;

    const salarioEquivalenteMEI = (resultadoCLT + descontoMEI) / (1 - aliquotaSimplesValue) ;
    const salarioEquivalenteSimples = (resultadoCLT + descontoSimples) / (1 - aliquotaSimplesValue) ;

    setResult(
      `<table class="table table-bordered">
        <thead>
          <tr>
            <th>Comparativo CLT, MEI e Simples Nacional</th>
            <th>CLT</th>
            <th>MEI</th>
            <th>SIMPLES NACIONAL</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Salário</td>
            <td>R$ ${salarioCLT.toFixed(2)}</td>
            <td>R$ ${salarioCLT.toFixed(2)}</td>
            <td>R$ ${salarioCLT.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Descontos</td>
            <td>- R$ ${descontoCLT.toFixed(2)}</td>
            <td>- R$ ${descontoMEI.toFixed(2)}</td>
            <td>- R$ ${descontoSimples.toFixed(2)}</td>
          </tr>
          <tr>
            <td>Benefícios</td>
            <td>R$ ${beneficiosCLT.toFixed(2)}</td>
            <td>-</td>
            <td>-</td>
          </tr>
          <tr>
            <td>Resultado</td>
            <td>R$ ${resultadoCLT.toFixed(2)}</td>
            <td>R$ ${resultadoMEI.toFixed(2)}</td>
            <td>R$ ${resultadoSimples.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>

      <h3>Remuneração Líquida Equivalente</h3>
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>CLT</th>
            <th>MEI</th>
            <th>SIMPLES NACIONAL</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>R$ ${salarioCLT.toFixed(2)}</td>
            <td>R$ ${salarioEquivalenteMEI.toFixed(2)}</td>
            <td>R$ ${salarioEquivalenteSimples.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
      <p><b>Confira as atividades permitidas para MEI!</b><br />
      Faturamento até R$180.000 com pró-labore de R$1.100,00 e R$100,00 de Contabilidade.<br />
      Remuneração máxima mensal permitida para MEI: R$ 6.750,00.<br /> 
      Consideramos aqui os benefícios legais (Férias, 13º, INSS e FGTS) acrescidos dos benefícios declarados.</p>`
    );
  };

  useEffect(() => {
    document.title = "Calculadora CLT x PJ";
  }, []);

  return (
    <div style={{
      backgroundImage: "url('https://www.contabilidadeolimpia.com.br/wp-content/uploads/2023/02/1014-scaled.jpg')",
      backgroundSize: "cover",
      backgroundPosition: "center",
    }} className="bg-primary text-white min-vh-100 d-flex flex-column justify-content-center">
      <div className="container text-center">
        {/* Logo centralizada */}
        <div className="mb-4">
          <img
            src="https://www.contabilidadeolimpia.com.br/wp-content/uploads/2023/02/LOGO-BRANCA-1024x331.png"
            alt="Logo"
            className="img-fluid"
            style={{width: 450}}
          />
        </div>
        
        <div className="card shadow-lg p-4 rounded">
          <h2 className="mb-4">Simule sua Renda - Comparativo CLT x PJ</h2>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label className="form-label">Salário Mensal/Bruto (R$)</label>
              <input
                type="text"
                value={salaryCLT}
                onChange={(e) => setSalaryCLT(e.target.value)}
                className="form-control"
                placeholder="Exemplo: 3.000,00"
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Vale Transporte (R$)</label>
              <input
                type="text"
                value={valeTransporte}
                onChange={(e) => setValeTransporte(e.target.value)}
                className="form-control"
                placeholder="Exemplo: 150,00"
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Vale Refeição (R$)</label>
              <input
                type="text"
                value={valeRefeicao}
                onChange={(e) => setValeRefeicao(e.target.value)}
                className="form-control"
                placeholder="Exemplo: 300,00"
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Outros Benefícios (R$)</label>
              <input
                type="text"
                value={outrosBeneficios}
                onChange={(e) => setOutrosBeneficios(e.target.value)}
                className="form-control"
                placeholder="Exemplo: 100,00"
              />
            </div>
            <div className="col-md-6 mb-3">
              <label className="form-label">Alíquota do Simples Nacional (%)</label>
              <input
                type="number"
                className="form-control"
                value={aliquotaSimples}
                onChange={(e) => setAliquotaSimples(e.target.value)}
                placeholder="Exemplo: 16"
              />
            </div>
          </div>
          <button className="btn btn-primary w-100 mb-4" onClick={handleCalculate}>
            Calcular
          </button>
          <div
            className="result-container mt-4"
            dangerouslySetInnerHTML={{ __html: result }}
          />
        </div>
      </div>
    </div>
  );
};

export default App;
